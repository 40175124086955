import { SVGProps } from '.';

export default function DropdownArrowDown(props: SVGProps) {
  return (
    <svg width='21' height='11' viewBox='0 0 21 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_242_9570)'>
        <path
          // eslint-disable-next-line max-len
          d='M5.86824 0C8.98352 0 11.5087 2.45229 11.5087 5.47713C11.5087 8.50197 8.98332 10.9541 5.86824 10.9541C2.75315 10.9541 0.227539 8.50197 0.227539 5.47713C0.227539 2.45229 2.75295 0 5.86824 0ZM14.876 0.320836C16.4337 0.320836 17.6964 2.62915 17.6964 5.47713H17.6966C17.6966 8.32436 16.4339 10.6334 14.8762 10.6334C13.3186 10.6334 12.0559 8.32436 12.0559 5.47713C12.0559 2.62989 13.3184 0.320836 14.876 0.320836ZM19.2356 0.857903C19.7833 0.857903 20.2275 2.92596 20.2275 5.47713C20.2275 8.02755 19.7835 10.0964 19.2356 10.0964C18.6877 10.0964 18.2439 8.02811 18.2439 5.47713C18.2439 2.92615 18.6879 0.857903 19.2356 0.857903Z'
          fill='#4A687F'
        />
      </g>
      <defs>
        <clipPath id='clip0_242_9570'>
          <rect width='20' height='11' fill='white' transform='translate(0.227539)' />
        </clipPath>
      </defs>
    </svg>
  );
}
