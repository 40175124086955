import { SVGProps } from '.';

export default function DropdownArrowDown(props: SVGProps) {
  return (
    <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_242_9564)'>
        <path
          // eslint-disable-next-line max-len
          d='M11.6742 0.96113C10.7943 0.553152 9.8522 0.253053 8.86739 0.082408C8.8499 0.0784851 8.83242 0.0882923 8.82271 0.103984C8.70228 0.321703 8.56631 0.60611 8.47307 0.829714C7.4125 0.668876 6.35775 0.668876 5.32049 0.829714C5.22725 0.602188 5.0874 0.321703 4.96502 0.103984C4.95531 0.0882923 4.93783 0.0804466 4.92035 0.082408C3.93553 0.253053 2.99345 0.553152 2.11352 0.96113C2.10575 0.965053 2.09992 0.970937 2.0941 0.976821C0.307053 3.67379 -0.182442 6.30407 0.0564782 8.90297C0.0584207 8.91474 0.064248 8.92847 0.0739602 8.93631C1.25302 9.81111 2.39712 10.3427 3.51791 10.6938C3.53539 10.6996 3.55481 10.6918 3.56647 10.6781C3.83258 10.3113 4.06762 9.92684 4.27157 9.52082C4.28323 9.49728 4.27157 9.46982 4.24826 9.46002C3.87337 9.31683 3.51596 9.1403 3.17215 8.9422C3.14496 8.92651 3.14301 8.88728 3.16827 8.86766C3.24014 8.81274 3.31201 8.75586 3.38194 8.69898C3.39359 8.68917 3.41107 8.68721 3.42661 8.6931C5.68373 9.73462 8.12732 9.73462 10.3572 8.6931C10.3728 8.68525 10.3903 8.68721 10.4019 8.69898C10.4718 8.75586 10.5437 8.81471 10.6156 8.86766C10.6408 8.88728 10.6389 8.92651 10.6117 8.9422C10.2679 9.14423 9.91048 9.31683 9.53558 9.46002C9.51033 9.46982 9.50062 9.49728 9.51228 9.52278C9.72012 9.9288 9.95515 10.3132 10.2154 10.68C10.2271 10.6957 10.2465 10.7016 10.264 10.6957C11.3906 10.3446 12.5328 9.81307 13.7138 8.93828C13.7235 8.93043 13.7313 8.91866 13.7313 8.90689C14.0187 5.90198 13.2495 3.29327 11.6917 0.980744C11.6898 0.968975 11.682 0.963091 11.6742 0.96113ZM4.60956 7.32206C3.9297 7.32206 3.37028 6.69244 3.37028 5.91767C3.37028 5.1429 3.91999 4.51328 4.60956 4.51328C5.30495 4.51328 5.86049 5.14879 5.84883 5.91767C5.84883 6.69047 5.29912 7.32206 4.60956 7.32206ZM9.19177 7.32206C8.51192 7.32206 7.9525 6.69244 7.9525 5.91767C7.9525 5.1429 8.50221 4.51328 9.19177 4.51328C9.88717 4.51328 10.4427 5.14879 10.431 5.91767C10.431 6.69047 9.88717 7.32206 9.19177 7.32206Z'
          fill='#4A687F'
        />
      </g>
      <defs>
        <clipPath id='clip0_242_9564'>
          <rect width='13.7913' height='10.7879' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
