/* eslint-disable max-len */
import { SVGProps } from '.';

export default function InteractionError(props: SVGProps) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8 1.33331C11.682 1.33331 14.6667 4.31798 14.6667 7.99998C14.6667 11.682 11.682 14.6666 8 14.6666C4.318 14.6666 1.33333 11.682 1.33333 7.99998C1.33333 4.31798 4.318 1.33331 8 1.33331ZM8 11.3333C7.58295 11.3338 7.16953 11.2557 6.78133 11.1033L5.29067 12.5946C6.1111 13.0796 7.04696 13.3348 8 13.3333C8.95304 13.3348 9.8889 13.0796 10.7093 12.5946L9.21867 11.1033C8.83047 11.2557 8.41705 11.3338 8 11.3333ZM2.66667 7.99998C2.66667 8.98931 2.936 9.91531 3.40533 10.7093L4.89667 9.21865C4.74424 8.83045 4.66622 8.41703 4.66667 7.99998C4.66667 7.56998 4.748 7.15931 4.89667 6.78131L3.40533 5.29065C2.92038 6.11108 2.66523 7.04694 2.66667 7.99998ZM12.5947 5.29065L11.1033 6.78131C11.252 7.15931 11.3333 7.56998 11.3333 7.99998C11.3333 8.42998 11.252 8.84065 11.1033 9.21865L12.5947 10.7093C13.0796 9.88888 13.3348 8.95302 13.3333 7.99998C13.3348 7.04694 13.0796 6.11108 12.5947 5.29065ZM8 5.99998C7.46957 5.99998 6.96086 6.21069 6.58579 6.58577C6.21071 6.96084 6 7.46955 6 7.99998C6 8.53041 6.21071 9.03912 6.58579 9.41419C6.96086 9.78927 7.46957 9.99998 8 9.99998C8.53043 9.99998 9.03914 9.78927 9.41422 9.41419C9.78929 9.03912 10 8.53041 10 7.99998C10 7.46955 9.78929 6.96084 9.41422 6.58577C9.03914 6.21069 8.53043 5.99998 8 5.99998ZM8 2.66665C7.04696 2.66521 6.1111 2.92036 5.29067 3.40531L6.78133 4.89665C7.16953 4.74422 7.58295 4.6662 8 4.66665C8.43 4.66665 8.84067 4.74798 9.21867 4.89665L10.7093 3.40531C9.8889 2.92036 8.95304 2.66521 8 2.66665Z'
        fill='#EB5757'
      />
    </svg>
  );
}
