/* eslint-disable max-len */
import { SVGProps } from '.';

export default function AloeCapitalNavLogo(props: SVGProps) {
  return (
    <svg width='100' height='40' viewBox='0 0 100 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.0299 10.8L11.9905 23.2079C14.8656 20.9535 18.0514 19.1262 21.449 17.7827L18.0299 10.8Z'
        fill='#D9ED92'
      />
      <path
        d='M18.3977 30.2255H27.5226L24.371 23.7888C21.6462 26.0343 19.642 28.1932 18.3977 30.2255Z'
        fill='#52B69A'
      />
      <path
        d='M29.5852 17.8176C27.3422 17.9406 25.1271 18.3749 23.0037 19.108C13.6896 22.2314 6.85756 29.9347 6.6001 30.2255H16.2576C18.0461 26.7975 21.4514 23.6742 24.3664 21.3948C26.0319 20.0977 27.7747 18.903 29.5852 17.8176Z'
        fill='url(#paint0_linear_836_5721)'
      />
      <path
        d='M42.0327 10.8L51.718 30.2255H47.3486L45.8613 27.0673H38.3254L36.8095 30.2255H32.4045L42.0327 10.8ZM44.4452 23.8521L42.0327 18.6823L39.6274 23.8521H44.4452Z'
        fill='white'
      />
      <path
        d='M60.679 22.8747C60.6608 21.3294 61.1044 19.8137 61.953 18.522C62.8035 17.2275 64.0193 16.2147 65.4463 15.6122C66.8738 15.0081 68.4483 14.8403 69.971 15.1301C71.4954 15.4209 72.9 16.1555 74.0083 17.2418C74.8327 18.0514 75.4656 19.0351 75.8606 20.121C76.2565 21.2086 76.405 22.3708 76.2953 23.523C76.1858 24.6747 75.8208 25.7875 75.2269 26.7803C74.6344 27.7707 73.8273 28.6157 72.8652 29.2531C71.5745 30.1136 70.058 30.5728 68.5068 30.5726C67.4831 30.5923 66.4658 30.4074 65.5146 30.0287C64.5618 29.6493 63.694 29.084 62.9619 28.3658C62.2332 27.6511 61.6559 26.7971 61.2643 25.8546C60.872 24.9104 60.6729 23.8972 60.6789 22.8747H60.679ZM68.5068 26.8476C69.0323 26.8597 69.5549 26.765 70.0428 26.5695C70.5316 26.3735 70.9757 26.0808 71.3485 25.7089C71.7194 25.3385 72.0107 24.8963 72.2045 24.4094C72.3988 23.9217 72.4917 23.3995 72.4777 22.8747C72.4772 22.3549 72.3742 21.8402 72.1744 21.3603C71.9751 20.8811 71.6828 20.4462 71.3146 20.0806C70.5678 19.3397 69.5587 18.9237 68.5068 18.9232C67.4548 18.9237 66.4457 19.3397 65.6989 20.0806C65.3305 20.4461 65.0383 20.8811 64.839 21.3603C64.6393 21.8402 64.5362 22.3549 64.5358 22.8747C64.5205 23.401 64.6131 23.9249 64.8079 24.414C65.0023 24.9024 65.295 25.3456 65.6678 25.7161C66.0412 26.0884 66.4867 26.3807 66.9769 26.575C67.4679 26.7695 67.9932 26.8623 68.5211 26.8476H68.5068Z'
        fill='white'
      />
      <path
        d='M78.4052 22.9739C78.3807 21.9422 78.5665 20.9163 78.9513 19.9586C79.3352 19.0026 79.9105 18.1352 80.6416 17.4094C81.3763 16.6801 82.2507 16.1068 83.2124 15.7237C84.1725 15.3415 85.2002 15.1578 86.2333 15.1839C87.1834 15.1493 88.1305 15.3108 89.0154 15.6583C89.9017 16.0066 90.7072 16.533 91.3818 17.2052C92.053 17.8743 92.578 18.6754 92.9236 19.5579C93.2699 20.4419 93.4295 21.3879 93.3925 22.3365C93.4165 22.9262 93.3832 23.5168 93.293 24.1H82.1983C82.6824 26.0122 84.3332 27.2301 86.6033 27.2301C87.9641 27.2799 89.2902 26.7943 90.297 25.8775L92.5456 28.3562C91.7473 29.1028 90.8086 29.6835 89.784 30.0644C88.7611 30.4448 87.6727 30.6176 86.5822 30.5728C81.7644 30.5728 78.4052 27.4638 78.4052 22.9739ZM89.7274 21.2176C89.6561 19.61 88.219 18.5124 86.1692 18.5124C85.3047 18.4536 84.4458 18.6893 83.7324 19.1811C83.0206 19.6715 82.497 20.3898 82.2481 21.2176H89.7274Z'
        fill='white'
      />
      <path d='M57.9275 10.8H54.085V30.2256H57.9275V10.8Z' fill='white' />
      <defs>
        <linearGradient
          id='paint0_linear_836_5721'
          x1='8.01435'
          y1='32.2219'
          x2='29.602'
          y2='16.3505'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.19053' stopColor='#99D98C' />
          <stop offset='0.92763' stopColor='#D9ED92' />
        </linearGradient>
      </defs>
    </svg>
  );
}
