export const RESPONSIVE_BREAKPOINTS = {
  LG: 1400,
  MD: 1200,
  SM: 768,
  XS: 480,
};

export const RESPONSIVE_BREAKPOINT_LG = `${RESPONSIVE_BREAKPOINTS.LG}px`;
export const RESPONSIVE_BREAKPOINT_MD = `${RESPONSIVE_BREAKPOINTS.MD}px`;
export const RESPONSIVE_BREAKPOINT_SM = `${RESPONSIVE_BREAKPOINTS.SM}px`;
export const RESPONSIVE_BREAKPOINT_XS = `${RESPONSIVE_BREAKPOINTS.XS}px`;

export const BROWSE_CARD_WIDTH_XL = '580px';
export const BROWSE_CARD_WIDTH_LG = '500px';
export const BROWSE_CARD_WIDTH_MD = '400px';
