/* eslint-disable max-len */
import { SVGProps } from '.';

export default function Loading(props: SVGProps) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.364 5.63597L16.95 7.04997C15.8049 5.90477 14.2982 5.19203 12.6865 5.03321C11.0748 4.87439 9.45794 5.2793 8.11134 6.17896C6.76474 7.07862 5.77174 8.41736 5.30154 9.96708C4.83134 11.5168 4.91302 13.1816 5.53268 14.6779C6.15234 16.1741 7.27162 17.4092 8.69983 18.1727C10.128 18.9362 11.7768 19.1809 13.3652 18.8651C14.9536 18.5492 16.3833 17.6924 17.4108 16.4406C18.4382 15.1888 18.9999 13.6195 19 12H21C21 14.0822 20.278 16.0999 18.957 17.7095C17.6361 19.319 15.7979 20.4208 13.7557 20.827C11.7136 21.2332 9.5937 20.9187 7.75737 19.9372C5.92104 18.9556 4.48187 17.3677 3.68506 15.444C2.88825 13.5203 2.78311 11.3798 3.38756 9.38727C3.992 7.39473 5.26863 5.67343 6.99992 4.51663C8.73121 3.35984 10.81 2.83913 12.8822 3.04324C14.9544 3.24734 16.8917 4.16363 18.364 5.63597Z'
        fill='white'
      />
    </svg>
  );
}
