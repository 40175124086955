import { SVGProps } from '.';

export default function LeftChevron(props: SVGProps) {
  return (
    <svg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d={`M8.57233 9.99996L12.4911 13.9187L11.3717 15.0381L6.3335 9.99996L11.3717
         4.96179L12.4911 6.08121L8.57233 9.99996Z`}
        fill='white'
      />
    </svg>
  );
}
