export const RESPONSIVE_BREAKPOINTS = {
  LG: 1400,
  MD: 1200,
  TABLET: 992,
  SM: 768,
  XS: 480,
};

export const RESPONSIVE_BREAKPOINT_LG = `${RESPONSIVE_BREAKPOINTS.LG}px`;
export const RESPONSIVE_BREAKPOINT_MD = `${RESPONSIVE_BREAKPOINTS.MD}px`;
export const RESPONSIVE_BREAKPOINT_TABLET = `${RESPONSIVE_BREAKPOINTS.TABLET}px`;
export const RESPONSIVE_BREAKPOINT_SM = `${RESPONSIVE_BREAKPOINTS.SM}px`;
export const RESPONSIVE_BREAKPOINT_XS = `${RESPONSIVE_BREAKPOINTS.XS}px`;
