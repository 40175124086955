import { SVGProps } from '.';

export default function DropdownArrowDown(props: SVGProps) {
  return (
    <svg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_242_9568)'>
        <path
          // eslint-disable-next-line max-len
          d='M14.6482 1.27697C14.1406 1.49334 13.5952 1.64002 13.0229 1.70572C13.6069 1.36856 14.0558 0.834942 14.2668 0.199112C13.72 0.511053 13.1146 0.738041 12.4698 0.860163C11.954 0.330525 11.219 0 10.4053 0C8.84271 0 7.57598 1.21923 7.57598 2.72318C7.57598 2.9369 7.6008 3.14464 7.64907 3.34375C5.29765 3.23026 3.21241 2.14642 1.81672 0.498443C1.57331 0.900649 1.43333 1.36856 1.43333 1.86767C1.43333 2.81278 1.93257 3.64574 2.69178 4.13422C2.22771 4.12029 1.7919 3.9975 1.40988 3.79374C1.40988 3.80503 1.40988 3.81631 1.40988 3.82825C1.40988 5.1477 2.38493 6.24813 3.67924 6.49834C3.44203 6.56073 3.19172 6.59392 2.93382 6.59392C2.75178 6.59392 2.57456 6.57666 2.40148 6.5448C2.76143 7.62664 3.80681 8.41446 5.04458 8.43636C4.07643 9.1671 2.8559 9.60249 1.53055 9.60249C1.30231 9.60249 1.07682 9.58988 0.855469 9.56466C2.10772 10.3372 3.59511 10.7885 5.19284 10.7885C10.397 10.7885 13.2435 6.63905 13.2435 3.03977C13.2435 2.92163 13.2408 2.80416 13.2352 2.68734C13.789 2.30306 14.2689 1.8232 14.6482 1.27697Z'
          fill='#4A687F'
        />
      </g>
      <defs>
        <clipPath id='clip0_242_9568'>
          <rect width='13.7913' height='10.7879' fill='white' transform='translate(0.857422)' />
        </clipPath>
      </defs>
    </svg>
  );
}
